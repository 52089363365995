<template>
  <BOverlay
    :show="spinner"
    spinner-variant="primary"
    variant="semi-dark"
  >
    <BCard>
      <BRow>
        <BCol
          lg="2"
          md="3"
          sm="3"
          class="my-1"
        >
          <!-- BOTON MOSTRAR -->
          <btnMostrar
            :pageOptions.sync="pageOptions"
            :perPage.sync="perPage"
            :total.sync="items.length"
          />
        </BCol>
        <BCol
          lg="6"
          md="5"
          sm="5"
          class="my-1"
        >
          <!-- FILTRO -->
          <inputFiltro
            :filter.sync="filter"
          />
        </BCol>
        <!-- BOTON CREAR -->
        <BCol
          cols="4"
          md="4"
          class="my-1"
        >
          <btnCrear
            texto="Comunicación"
            modulo="comunicaciones"
            size="md"
            @processAdd="addComunicacion"
          />
        </BCol>
      </BRow>
      <!-- TABLA -->
      <BRow>
        <BCol cols="12">
          <BTable
            striped
            small
            hover
            noCollapse
            responsive
            show-empty
            class="mt-1"
            :items="items"
            :fields="fields"
            :busy="cargando"
            @filtered="onFiltered"
          >
            <template #empty="scope">
              <div v-if="optionsCursos.length > 1" cols="12">
                <b-alert
                  :show="!id_curso"
                  variant="primary"
                >
                  <div
                    class="alert-body text-center mt-2"
                  >
                    <feather-icon
                      icon="InfoIcon"
                      class="mr-50"
                    />
                    Primero seleccione un curso<br>
                  </div>
                </b-alert>
                <b-alert
                  :show="id_curso"
                  variant="primary"
                >
                  <div
                    class="alert-body text-center mt-2"
                  >
                    <feather-icon
                      icon="InfoIcon"
                      class="mr-50"
                    />
                    El curso filtrado no tiene comunicaciones creadas<br>
                  </div>
                </b-alert>
              </div>
            </template>
            <!-- Cargando -->
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <spinner />
              </div>
            </template>

            <!-- col: FECHA -->
            <template #cell(fecha_envio)="data">
              {{ formatoDiaMesYear(data.item.fecha_envio) }}
            </template>

            <!-- col: HORA -->
            <template #cell(hora_envio)="data">
              {{ formatHoraVer(data.item.hora_envio) }}
            </template>

            <!-- col: TIPO -->
            <template #cell(tipo)="data">
              <BBadge class="mr-25" :variant="data.item.tipo === 1 ? 'light-primary' : 'light-info'">
                {{ data.item.nombreTipo }}
              </BBadge>
            </template>

            <!-- col: CURSOS -->
            <template #cell(nombreCursos)="data">
              <template v-for="(curso, index) in data.item.nombreCursos">
                <BBadge v-if="index < 9" class="mr-25" variant="light-secondary">
                  {{ index < 8 ? curso : '...'}}
                </BBadge>
              </template>
            </template>

            <!-- col: ESTADO -->
            <template #cell(estado)="data">
              <BBadge v-if="data.item.estado === 0" class="mr-25" variant="danger">
                {{ data.item.nombreEstado}}
              </BBadge>

              <BBadge v-else-if="data.item.estado === 1" class="mr-25" variant="info">
                {{ data.item.nombreEstado}}
              </BBadge>

              <BBadge v-else-if="data.item.estado === 2" class="mr-25" variant="success">
                {{ data.item.nombreEstado}}
              </BBadge>
            </template>

            <!-- col: Action -->
            <template #cell(acciones)="data">
              <!-- MOODAL COMUNICACION -->
              <comunicaciones-ver-detalle
                :title="'Detalles de la comunicación '+data.item.titulo"
                :nombreModal="'comunicaciones_modal_'+data.item.id"
                :data="data"
              />

              <col-acciones-btnes-comunicaciones
                modulo="comunicaciones"
                :data="data"
                :modalRead="'comunicaciones_modal_'+data.item.id"
                @processGoToUpdate="goToUpdate"
                @processGoToRead="goToRead"
                @processRemove="remove(data.item)"
              />
            </template>
          </BTable>
        </BCol>

      </BRow>
    </BCard>
  </BOverlay>
</template>

<script>
import {
  BTable, BRow, BCol, BPagination, BFormCheckbox, BOverlay, BCard, BFormGroup,
  BAlert, BBadge
} from 'bootstrap-vue'

// FORMATOS
import { formatos } from '@/mixins/formatos'

import vSelect from 'vue-select'
import { mapGetters, mapActions, mapMutations } from 'vuex'

// COMPONENTES RECICLADOS
import btnCrear from '../components/List/btnCrear.vue'
import colAccionesBtnesComunicaciones from '../components/List/colAccionesBtnesComunicaciones.vue'
import spinner from '../components/spinner.vue'
import inputFiltro from '../components/List/inputFiltro.vue'
import btnMostrar from '../components/List/btnMostrar.vue'

import colEstado from '../components/List/colEstado.vue'

import comunicacionesVerDetalle from './components/ComunicacionesVerDetalle.vue'

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BPagination,
    BFormCheckbox,
    BOverlay,
    BCard,
    BFormGroup,
    vSelect,
    BAlert,
    BBadge,

    // COMPONENTES RECICLADOS
    btnCrear,
    spinner,
    colAccionesBtnesComunicaciones,
    inputFiltro,
    btnMostrar,
    comunicacionesVerDetalle,
  },
  mixins: [formatos],
  data() {
    return {
      cargando: false,
      spinner: false,
      items: [],
      optionsCursos: [],
      id_curso: null,

      perPage: 25,
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: '',
      filterOn: [],
      pageOptions: [10, 25, 50],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'fecha_envio',
          label: 'Fecha de Envio',
          sortable: true,
          tdClass: 'text-center',
          thStyle: {
            width: '12% !important',
            'text-align': 'center',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'hora_envio',
          label: 'Hora de Envio',
          sortable: true,
          tdClass: 'text-center',
          thStyle: {
            width: '10% !important',
            'text-align': 'center',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'titulo',
          label: 'Título',
          sortable: true,
          thStyle: {
            width: '20% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'tipo',
          label: 'Tipo',
          sortable: true,
          tdClass: 'text-center',
          thStyle: {
            width: '10% !important',
            'text-align': 'center',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'nombreCursos',
          label: 'Cursos',
          sortable: false,
          thStyle: {
            width: '30% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'estado',
          // label: 'Notificación',
          label: 'Estado',
          sortable: true,
          tdClass: 'text-center',
          thStyle: {
            width: '10% !important',
            'text-align': 'center',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
      fieldAcciones: [
        {
          key: 'acciones',
          label: 'acciones',
          tdClass: 'text-center',
          thStyle: {
            width: '10% !important',
            'text-align': 'center',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      getUser: 'auth/user',
      getCursos: 'cursos/getCursos',
      getComunicaciones: 'comunicaciones/getComunicaciones',
    }),
  },
  watch: {
    getComunicaciones(val) {
      this.totalRows = val.length
      this.items = []
      this.items = this.getComunicaciones
    },
    id_curso(id_curso) {

    },
  },
  mounted() {
    // this.cargarCursosJefatura()
    this.cargarComunicaciones()
    this.setTableList()
  },
  methods: {
    ...mapActions({
      attempt: 'auth/attempt',
      fetchCursosJefatura: 'cursos/fetchCursosJefatura',
      fetchCursosEstablecimiento: 'cursos/fetchCursosEstablecimiento',
      fetchComunicaciones: 'comunicaciones/fetchComunicaciones',
      removeComunicacion: 'comunicaciones/removeComunicacion',
    }),
    ...mapMutations('comunicaciones', ['setComunicacionSelected']),
    setTableList() {
      if (this.$can('update', 'comunicaciones')
        || this.$can('delete', 'comunicaciones')
      ) {
        this.fields.push(this.fieldAcciones)
      }
    },
    cargarCursosJefatura() {
      this.fetchCursosJefatura().then(() => {
        this.setCursosJefatura()
      })
    },
    cargarComunicaciones() {
      this.cargando = true
      this.fetchComunicaciones().then(() => {
        this.cargaCursos()
      })
    },
    cargaCursos() {
      this.fetchCursosEstablecimiento(this.getUser.id_establecimiento).then(() => {
        this.setCursos()
        this.cargando = false
      })
    },
    setCursos() {
      let idCursos
      this.items.forEach(comunicacion => {
        idCursos = comunicacion.cursos.split(',')
        let nombreCursos = []
        idCursos.forEach(id_curso => {
          const curso = this.getCursos.find(c => c.id === parseInt(id_curso))
          nombreCursos.push(curso.nombre+' '+ curso.letra)
        })
        comunicacion.nombreCursos = nombreCursos
      });
    },
    setCursosJefatura() {
      this.optionsCursos = []
      this.getCursos.forEach(curso => {
        const texto = `${curso.nombre} ${curso.letra}`
        this.optionsCursos.push({
          id: curso.id,
          texto,
        })
      })
      if (this.getCursos.length === 1) {
        this.id_curso = this.getCursos[0].id
      }
    },
    addComunicacion() {
      this.$router.replace({
        name: 'comunicacion-create',
      })
    },
    goToUpdate(comunicacion) {
      this.setComunicacionSelected(comunicacion)
      this.$router.push({
        name: 'comunicacion-update',
      })
    },
    goToRead() {

    },
    remove(comunicacion) {
      this.$swal({
        title: 'Eliminar comunicación!',
        text: `Estás seguro que deseas eliminar la comunicación "${comunicacion.titulo}"?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, eliminala!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        this.spinner = true
        if (result.value) {
          const data = {
            id: comunicacion.id,
            id_curso: this.id_curso
          }
          this.removeComunicacion(data).then(() => {
            this.$swal({
              icon: 'success',
              title: 'Eliminada con éxito!',
              text: `La comunicación "${comunicacion.titulo}", ha sido eliminada!`,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.spinner = false
          })
        } else {
          this.spinner = false
        }
      })
    },

    // Vuexy
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of
      // buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
